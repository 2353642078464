import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Flex,
  Table,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import {
  PendingReasonResponse,
  TicketsGet200ResponseRowsInnerStagesInfoInner,
  TicketsGet200ResponseRowsInnerStagesInfoInnerLogsInner,
} from "@sizlcorp/mbk-api-document/dist/models";
import Confirm from "components/Confirm";
import { useModal } from "components/Modal/ModalStackManager";
import {
  StepStatus,
  StepStatusData,
  StepStatusDataPending,
  StepStatusDataPendingKeys,
} from "constants/stepStatus";
import dayjs from "dayjs";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRepairMutation from "api/useRepairMutation";
import { ParkingHistory } from "components/Ticket/ParkingHistory";
import PendingReasons from "components/Ticket/PendingReasons";
import ProcessTypes from "components/Ticket/ProcessTypes";
import FileForm from "components/File/FileForm";
import { useQuery } from "@tanstack/react-query";
import { tickets } from "api/useTicketQuery";
import { masters } from "api/useMasterQuery";
import { AxiosResponse } from "axios";
import { users } from "api/useUserQuery";

const Repair = () => {
  const { openModal } = useModal();
  const navigate = useNavigate();

  const { ticketId } = useParams() as { ticketId: string };
  const { data: userInfo } = useQuery(users.who({}));
  const { data: ticketData } = useQuery({
    ...tickets.getView(
      { ticketId: Number(ticketId) },
      {
        params: {
          populate: [
            "stagesInfo",
            "createUser",
            "assignedAdviser",
            "assignedTechnician",
          ],
        },
      }
    ),
    select: (data) => data.data,
  });

  const parkingLocationRef = useRef<HTMLInputElement>(null);

  const {
    startMutate,
    pendingMutate,
    resumeMutate,
    startProcessMutate,
    finishProcessMutate,
    finishMutate,
    restartMutate,
    saveParkingLocationMutate,
    updateMutate,
  } = useRepairMutation();

  // 파일 업로드
  const handleFileUpload = () => {
    ticketData?.id &&
      openModal(<FileForm ticketId={ticketData.id} />, null, "첨부파일 업로드");
  };

  // 주차 위치 저장
  const saveParkingLocation = () => {
    const parkingLocation = parkingLocationRef.current?.value;

    if (!parkingLocation || parkingLocation === ticketData?.parkingLocation) {
      return alert("주차 위치를 입력해주세요.");
    }

    saveParkingLocationMutate({
      ticketId: ticketData?.id as number,
      ticketsTicketIdUpdateParkingLocationPutRequest: {
        parkingLocation: parkingLocationRef.current?.value,
      },
    });
  };

  // pendingReason 조회해서 버튼 안보이게 처리
  const { data: pendingReasonData } = useQuery({
    ...masters.getPendingReason({
      query: [
        JSON.stringify({
          $and: {
            pendingType:
              StepStatusDataPending[
                ticketData?.stepStatus as StepStatusDataPendingKeys
              ],
            $or: [
              {
                siteId: {
                  $eq: ticketData?.siteId,
                },
              },
              {
                siteId: {
                  $is: null,
                },
              },
            ],
          },
        }),
      ],
    }),
    select: (data) => data.data as PendingReasonResponse[],
    enabled:
      ticketData?.siteId !== undefined && ticketData?.stepStatus !== undefined,
  });

  // 상세정비 조회해서 버튼 안보이게 처리
  const { data: processData } = useQuery({
    ...masters.getProcess({
      query: [
        JSON.stringify({
          site_id: ticketData?.siteId,
        }),
      ],
    }),
    select: (data: AxiosResponse<object[], any>) => data.data as object[],
    enabled: ticketData?.siteId !== undefined,
  });

  // 비어 있지 않은 logs를 가진 마지막 stageInfo 찾기
  const lastStageWithLogs =
    ticketData?.stagesInfo &&
    ticketData?.stagesInfo
      .slice()
      .reverse()
      .find((stage) => stage.logs && stage.logs.length > 0);

  // 해당 stageInfo의 마지막 로그
  const finalLog =
    lastStageWithLogs?.logs &&
    lastStageWithLogs?.logs[lastStageWithLogs.logs.length - 1];

  // 마지막 stageId와 stageInfo의 길이가 같은지 확인 - 정비대기 상태 체크
  const stageId = ticketData && ticketData?.stageId;
  const stageInfoLength =
    ticketData?.stagesInfo && ticketData?.stagesInfo.length;
  const isStage = stageId && stageInfoLength && stageId < stageInfoLength;

  return (
    <RepairContainer>
      <Flex direction="column" gap="1rem">
        <CarNumTitle order={2} weight={800}>
          접수건 조회
        </CarNumTitle>
        <Table withBorder withColumnBorders w="100%">
          <tbody>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  고객명
                </Text>
              </Td>
              <td>
                <WorkBreakText
                  fz="lg"
                  p="xs"
                  fw={500}
                  style={{ wordBreak: "break-all" }}
                >
                  {ticketData?.customerName}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  고객 연락처
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.mobile}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  차량번호
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.plateCode}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  VIN
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.vinCode}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  모델명
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.carModel}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  접수자명
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.createUser?.name}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  입고일시
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.incomingAt
                    ? dayjs(ticketData?.incomingAt).isValid()
                      ? dayjs(ticketData?.incomingAt).format(
                          "YYYY-MM-DD A hh:mm"
                        )
                      : "Invalid Date"
                    : ""}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text c={"red"} fz="xl" p="sm" fw={700}>
                  *비고(내부용)
                </Text>
              </Td>
              <td>
                <WorkBreakText c={"red"} fz="lg" p="xs" fw={500}>
                  {ticketData?.context}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  알림톡 사용
                </Text>
              </Td>
              <td>
                <Flex gap="lg" p="sm">
                  <Checkbox
                    disabled
                    checked={ticketData?.usesNotification ?? false}
                    label="사용"
                  />
                  <Checkbox
                    disabled
                    checked={!ticketData?.usesNotification}
                    label="미사용"
                  />
                </Flex>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  담당 어드바이저
                </Text>
              </Td>
              <td>
                <WorkBreakText fz="lg" p="xs" fw={500}>
                  {ticketData?.assignedAdviser?.name}
                </WorkBreakText>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  담당 테크니션
                </Text>
              </Td>
              <td>
                <Flex gap="sm">
                  <WorkBreakText fz="lg" p="sm" fw={500}>
                    {ticketData?.assignedTechnician?.name}
                  </WorkBreakText>
                  {/* {
                                        !ticketData?.assignedTechnician?.name &&
                                        <Button color="violet" size="lg" onClick={() => {
                                            openModal(<ResponsibleParty />, null, "담당자 배정", true).then((result) => {
                                                if (result === false) return;

                                                updateMutate(
                                                    {
                                                        ticketId: ticketData?.id as number,
                                                        ticketsTicketIdDeleteRequest: {
                                                            assignedTechnicianId: result.userId
                                                        }
                                                    },
                                                    {
                                                        onSuccess: () => {
                                                            alert("담당자가 배정되었습니다.");
                                                        }
                                                    }
                                                );
                                            });
                                        }}>담당자 배정</Button>
                                    } */}
                </Flex>
              </td>
            </tr>
            <tr>
              <Td>
                <Text fz="xl" p="sm" fw={700}>
                  주차위치
                </Text>
              </Td>
              <td>
                <Flex gap="sm" direction="column">
                  <TextInput
                    placeholder="주차위치"
                    w="100%"
                    size="lg"
                    defaultValue={ticketData?.parkingLocation ?? ""}
                    ref={parkingLocationRef}
                    key="mobile"
                  />
                  <Button
                    color="gray"
                    size="lg"
                    onClick={() => saveParkingLocation()}
                  >
                    저장
                  </Button>
                  <Button
                    color="cyan"
                    size="lg"
                    onClick={() => {
                      openModal(
                        <ParkingHistory ticketId={Number(ticketId)} />,
                        null,
                        "주차 이력"
                      );
                    }}
                  >
                    주차 이력
                  </Button>
                </Flex>
              </td>
            </tr>
          </tbody>
        </Table>
      </Flex>
      <Flex w="100%" direction="column" gap="sm">
        {ticketData?.stagesInfo?.map(
          (info: TicketsGet200ResponseRowsInnerStagesInfoInner) => {
            return (
              info.logs &&
              info?.logs.map(
                (
                  log: TicketsGet200ResponseRowsInnerStagesInfoInnerLogsInner
                ) => {
                  const isLastLog = log === finalLog; // 전체 로그 중 마지막 로그인지 확인
                  const statusText =
                    log.stepStatus &&
                    StepStatus[log.stepStatus as keyof typeof StepStatus]; // 상태 한글화
                  return (
                    <Flex
                      key={log.createdAt}
                      p="1rem"
                      direction="column"
                      gap="sm"
                      bg={isLastLog ? "blue.4" : "gray.1"}
                    >
                      <Text
                        fw={500}
                        fz="xl"
                        maw="12rem"
                        color={isLastLog ? "white" : ""}
                      >
                        {dayjs(log.createdAt).format("YYYY-MM-DD A hh:mm")}
                      </Text>
                      <Flex direction="column" gap="xs" w="100%">
                        <Text fw={700} fz="xl" color={isLastLog ? "white" : ""}>
                          {statusText}
                        </Text>
                        {log?.additional?.pendingReason && (
                          <Text
                            fw={700}
                            fz="xl"
                            color={isLastLog ? "white" : ""}
                            style={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            지연 사유: {log.additional.pendingReason.name}
                          </Text>
                        )}
                        {log?.additional?.process && (
                          <Text
                            fw={700}
                            fz="xl"
                            color={isLastLog ? "white" : ""}
                            style={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            상세 정비: {log.additional.process.name}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  );
                }
              )
            );
          }
        )}
      </Flex>
      <Flex direction="column" justify="space-between" gap="md">
        <Flex gap="md">
          <Button
            w="100%"
            size="xl"
            miw="6.45rem"
            color="indigo"
            onClick={() => navigate("/qr")}
          >
            목록으로
          </Button>
          <Button w="100%" size="xl" miw="6.45rem" onClick={handleFileUpload}>
            첨부파일
          </Button>
        </Flex>
        <Flex justify="flex-end" gap="md">
          {[StepStatusData.STAGE_IN_PROGRESS].includes(
            ticketData?.stepStatus ?? ""
          ) &&
          processData?.length &&
          processData?.length > 0 ? (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="cyan"
              onClick={() => {
                ticketData?.siteId &&
                  openModal(
                    <ProcessTypes siteId={ticketData.siteId} />,
                    null,
                    "상세 정비 선택"
                  ).then((processId: string) => {
                    processId &&
                      startProcessMutate({
                        ticketId: ticketData?.id as number,
                        ticketsTicketIdStartProcessPutRequest: {
                          processId: Number(processId),
                        },
                      });
                  });
              }}
            >
              상세정비
            </Button>
          ) : null}
          {[
            StepStatusData.WAITING,
            StepStatusData.TEMPORARY_STAGE_IN_PROGRESS,
            StepStatusData.STAGE_IN_PROGRESS,
            StepStatusData.STAGE_WAITING,
            StepStatusData.PROCESSING,
          ].includes(ticketData?.stepStatus ?? "") &&
            pendingReasonData?.length !== 0 && (
              <Button
                w="100%"
                size="xl"
                miw="5.8rem"
                p="sm"
                color="gray"
                onClick={() => {
                  if (!ticketData?.stepStatus) return;
                  ticketData?.siteId &&
                    openModal(
                      <PendingReasons
                        usesNotification={
                          ticketData?.usesNotification as boolean
                        }
                        siteId={ticketData.siteId}
                        pendingType={
                          ticketData.stepStatus as StepStatusDataPendingKeys
                        }
                      />,
                      null,
                      "지연 사유 선택"
                    ).then((result) => {
                      if (result === false) return;

                      const {
                        pendingReasonId,
                        message,
                        usePendingNotification,
                      } = result;
                      pendingMutate({
                        ticketId: ticketData?.id as number,
                        ticketsTicketIdPendAutoPutRequest: {
                          pendingReasonId: Number(pendingReasonId),
                          message,
                          usePendingNotification,
                          pendingType:
                            ticketData.stepStatus === "WAITING"
                              ? "ACCEPT_PENDING"
                              : "PENDING",
                        },
                      });
                    });
                }}
              >
                {StepStatusData.WAITING === ticketData?.stepStatus
                  ? "점검지연"
                  : "정비지연"}
              </Button>
            )}
          {[StepStatusData.ACCEPT_PENDING].includes(
            ticketData?.stepStatus ?? ""
          ) && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="teal"
              onClick={() => {
                let isResume = finalLog?.sentMessage;
                resumeMutate({
                  ticketId: ticketData?.id as number,
                  ticketsTicketIdResumeAutoPutRequest: {
                    useResumeNotification: isResume,
                  },
                });
              }}
            >
              점검재개
            </Button>
          )}
          {[StepStatusData.STAGE_IN_PROGRESS].includes(
            ticketData?.stepStatus ?? ""
          ) && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="green"
              onClick={() =>
                finishMutate({
                  ticketId: ticketData?.id as number,
                })
              }
            >
              정비완료
            </Button>
          )}
          {[StepStatusData.PROCESSING].includes(
            ticketData?.stepStatus ?? ""
          ) && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="green"
              onClick={() =>
                finishProcessMutate({
                  ticketId: ticketData?.id as number,
                })
              }
            >
              상세정비완료
            </Button>
          )}
          {[StepStatusData.STAGE_IN_PROGRESS].includes(
            ticketData?.stepStatus ?? ""
          ) &&
            isStage && (
              <Button
                w="100%"
                size="xl"
                miw="5.8rem"
                p="sm"
                color="yellow"
                onClick={() => {
                  finishMutate({
                    ticketId: ticketData?.id as number,
                  });
                }}
              >
                정비대기
              </Button>
            )}
          {([StepStatusData.TEMPORARY_STAGE_IN_PROGRESS].includes(
            ticketData?.stepStatus ?? ""
          ) ||
            [StepStatusData.WAITING].includes(
              ticketData?.stepStatus ?? ""
            )) && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="orange"
              onClick={() => {
                const ticketId = ticketData?.id as number;
                const assignedTechnicianId = userInfo?.id;
                // const assignedTechnicianId = ticketData?.assignedTechnicianId;
                const updateAndStartRepair = () => {
                  updateMutate(
                    {
                      ticketId,
                      ticketsTicketIdDeleteRequest: {
                        assignedTechnicianId,
                      },
                    },
                    {
                      onSuccess: () => {
                        startMutate({ ticketId });
                      },
                    }
                  );
                };

                // 임시 정비 진행중인 경우 정비 시작을 누를 경우, 알림창 메시지 보내지 않게
                if (
                  [StepStatusData.TEMPORARY_STAGE_IN_PROGRESS].includes(
                    ticketData?.stepStatus ?? ""
                  )
                ) {
                  updateAndStartRepair();
                } else {
                  openModal(
                    <Confirm
                      usesNotification={ticketData?.usesNotification as boolean}
                    />,
                    null,
                    "정비 시작"
                  ).then((result) => {
                    if (result) {
                      updateAndStartRepair();
                    }
                  });
                }
              }}
            >
              정비시작
            </Button>
          )}
          {[
            StepStatusData.PENDING,
            StepStatusData.WAITING_PENDING,
            StepStatusData.IN_PROGRESS_PENDING,
            StepStatusData.PROCESS_PENDING,
          ].includes(ticketData?.stepStatus ?? "") && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="teal"
              onClick={() => {
                let isResume = finalLog?.sentMessage;
                resumeMutate({
                  ticketId: ticketData?.id as number,
                  ticketsTicketIdResumeAutoPutRequest: {
                    useResumeNotification: isResume,
                  },
                });
              }}
            >
              정비재개
            </Button>
          )}
          {[StepStatusData.STAGE_WAITING].includes(
            ticketData?.stepStatus ?? ""
          ) && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="teal"
              onClick={() =>
                startMutate({
                  ticketId: ticketData?.id as number,
                })
              }
            >
              정비진행
            </Button>
          )}
          {[StepStatusData.DONE].includes(ticketData?.stepStatus ?? "") && (
            <Button
              w="100%"
              size="xl"
              miw="5.8rem"
              p="sm"
              color="orange"
              onClick={() =>
                restartMutate({
                  ticketId: ticketData?.id as number,
                  ticketsTicketIdCancelFinishAutoPutRequest: {},
                })
              }
            >
              정비완료 취소
            </Button>
          )}
        </Flex>
      </Flex>
    </RepairContainer>
  );
};

export default Repair;

const RepairContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 4rem;
  padding: 3rem;
  background: #fff;
  border-radius: 4rem;
  font-family: MBK CorpoS;

  @media (max-width: 768px) {
    padding: 0.5rem;
    border-radius: 1rem;
    gap: 2rem;
  }
`;

const CarNumTitle = styled(Title)`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

// TODO: theme color 적용하기 (mes에 있음)
const Td = styled.td`
  width: 17%;
  background-color: #f7f7f7;

  @media (max-width: 768px) {
    .mantine-Text-root {
      padding: 0.5rem 0;
      text-align: center;
    }
  }
`;

const WorkBreakText = styled<any>(Text)`
  word-break: break-word;
`;
