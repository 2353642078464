import styled from "@emotion/styled";
import { Button, Flex, Select } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { masters } from "api/useMasterQuery";
import { AxiosResponse } from "axios";
import { useModal } from "components/Modal/ModalStackManager";
import { useState } from "react";

interface ProcessTypesProps {
  siteId: number;
}

const ProcessTypes = ({ siteId }: ProcessTypesProps) => {
  const { closeModal } = useModal();
  const [value, setValue] = useState<string>("");
  const { data: processData } = useQuery({
    ...masters.getProcess({
      query: [
        JSON.stringify({
          site_id: siteId,
        }),
      ],
    }),
    select: (data: AxiosResponse<object[], any>) => data.data as object[],
    enabled: siteId !== undefined,
  });

  // TODO: processType 타입 수정 필요 (현재 object로 되어있음)
  const processTypes = processData?.map((processType: any) => {
    return {
      value: processType.id.toString(),
      label: processType.name,
    };
  });

  return (
    <ProcessContent w="30rem" h="auto" direction="column" gap="sm">
      <Select
        data={processTypes ?? []}
        withinPortal
        size="lg"
        onChange={(e: string) => setValue(e)}
      />
      <Button onClick={() => closeModal(value)} disabled={!value.length}>
        저장
      </Button>
    </ProcessContent>
  );
};

export default ProcessTypes;

const ProcessContent = styled(Flex)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;
