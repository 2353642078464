import styled from "@emotion/styled";
import { Flex, Table, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { masters } from "api/useMasterQuery";

interface ParkingHistoryProps {
  ticketId: number;
}

export const ParkingHistory = ({ ticketId }: ParkingHistoryProps) => {
  const { data } = useQuery({
    ...masters.getParkingHistory({
      ticketId: ticketId,
    }),
    enabled: !!ticketId,
    select: (data) => data.data,
  });

  return (
    <Flex w="100%" style={{ flexGrow: 1 }}>
      <Table
        w="100%"
        withBorder
        withColumnBorders
        style={{ tableLayout: "fixed" }}
      >
        <thead>
          <tr>
            <Th width={40}>주차위치</Th>
            <Th width={60}>주차된 시간</Th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={item.createdAt}>
              <td>
                <Text fw={!index ? 700 : 400} c={!index ? "blue" : ""}>
                  {item.description}
                </Text>
              </td>
              <td>
                <Text fw={!index ? 700 : 400} c={!index ? "blue" : ""}>
                  {dayjs(item.createdAt).format("YYYY-MM-DD A HH:mm")}
                </Text>
              </td>
            </tr>
          ))}
          {data && data.length === 0 && (
            <tr>
              <td colSpan={2}>
                <Text ta="center">주차 내역이 없습니다.</Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Flex>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;
