import styled from "@emotion/styled";
import {
  ActionIcon,
  Chip,
  Flex,
  Table,
  Title,
  Text,
  Pagination,
} from "@mantine/core";
import useScanQR from "hook/useScanQR";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { tickets } from "api/useTicketQuery";
import { users } from "api/useUserQuery";
import { IconReload } from "@tabler/icons-react";
import SearchBox from "components/SearchBox";
import { allSearchFields } from "constants/searchHeader";
import { InProgressStatus } from "constants/stepStatus";
import { TicketsGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";

const QR = () => {
  const { qrData } = useScanQR();
  const navigate = useNavigate();

  const [assignedValue, setAssignedValue] = useState<string>("all");
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [searchFields, setSearchFields] = useState<string[]>([]);

  useEffect(() => {
    if (qrData) {
      const path = new URL(qrData).pathname;
      navigate(path);
    }
  }, [navigate, qrData]);

  const { data: userData } = useQuery(users.who({}));

  const { data: ticketData, refetch } = useQuery({
    ...tickets.getListView({
      query: [
        JSON.stringify({
          stepStatus: [
            "ACCEPT_PENDING",
            "WAITING",
            "TEMPORARY_STAGE_IN_PROGRESS",
            "PENDING",
            "STAGE_IN_PROGRESS",
            "STAGE_WAITING",
            "WAITING_PENDING",
            "IN_PROGRESS_PENDING",
            "PROCESSING",
            "PROCESS_PENDING",
            "DONE",
          ],
          siteId:
            Number(localStorage.getItem("siteId")) === 99
              ? undefined
              : Number(localStorage.getItem("siteId")),
          ...(assignedValue === "assigned" && {
            assignedTechnicianId: { $eq: userData?.id },
          }),
          ...(assignedValue === "unassigned" && {
            assignedTechnicianId: { $eq: null },
          }),
        }),
      ],
      ...(search && { search }), // search 값이 존재할 때만 추가
      searchFields: searchFields.length ? searchFields : allSearchFields,
      page,
      pageSize: 10, // pageSize는 일괄적으로 10으로 고정
      populate: [
        "createUser",
        "stagesInfo",
        "assignedAdviser",
        "assignedTechnician",
      ],
      sort: "-createdAt",
    }),
    keepPreviousData: true,
  });

  return (
    <Flex direction="column" w="100%" h="100%" gap="md">
      <Flex
        direction="column"
        w="100%"
        h="100%"
        justify="center"
        align="center"
        gap="xs"
        p="md"
        bg="#FFFFFF"
        style={{ borderRadius: "2rem" }}
      >
        <Title order={2}>QR 코드 인식</Title>
        <QRReaderBox id="qr-reader" />
      </Flex>
      <Flex
        direction="column"
        w="100%"
        gap="md"
        justify="center"
        align="center"
        p="md"
        bg="#FFFFFF"
        style={{ borderRadius: "2rem" }}
      >
        <Flex gap="md" align="center" justify="center">
          <Title order={2}>접수차량 목록</Title>
          <ActionIcon onClick={() => refetch()}>
            <IconReload />
          </ActionIcon>
        </Flex>
        <SearchBox
          search={search}
          setSearch={setSearch}
          searchFields={searchFields}
          setSearchFields={setSearchFields}
          setPage={setPage}
        />
        <Flex w="100%" gap="md" justify="flex-end">
          <Chip.Group
            multiple={false}
            value={assignedValue}
            onChange={(e) => {
              setAssignedValue(e);
              setPage(1);
            }}
          >
            <Chip value="all" radius="xs" variant="filled" size="sm">
              전체
            </Chip>
            <Chip value="unassigned" radius="xs" variant="filled" size="sm">
              미배정 건
            </Chip>
            <Chip value="assigned" radius="xs" variant="filled" size="sm">
              담당 건
            </Chip>
          </Chip.Group>
        </Flex>
      </Flex>
      <Flex direction="column" align="flex-start" bg="white" w="100%">
        <Table withBorder highlightOnHover>
          <thead>
            <tr>
              <Th width={25}>
                <Text fz="lg" ta="center">
                  담당 어드바이저
                </Text>
              </Th>
              <Th width={25}>
                <Text fz="lg" ta="center">
                  차량번호
                </Text>
              </Th>
              <Th width={25}>
                <Text fz="lg" ta="center">
                  담당 테크니션
                </Text>
              </Th>
              <Th width={25}>
                <Text fz="lg" ta="center">
                  주차위치
                </Text>
              </Th>
            </tr>
          </thead>
          <tbody>
            {ticketData?.data?.rows?.map(
              (item: TicketsGet200ResponseRowsInner) => {
                return (
                  <tr
                    key={item.id}
                    onClick={() => navigate(`/repair/${String(item.id)}`)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: `${
                        InProgressStatus.includes(item.stepStatus ?? "")
                          ? "#008DFC"
                          : "#FFFFFF"
                      }`,
                    }}
                  >
                    <td
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      <Text
                        fz="xl"
                        p="sm"
                        ta="center"
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {item.assignedAdviser?.name}
                      </Text>
                    </td>
                    <td
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      <Text
                        fz="xl"
                        p="sm"
                        ta="center"
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {item.plateCode}
                      </Text>
                    </td>
                    <td
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      <Text
                        fz="xl"
                        p="sm"
                        ta="center"
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {item.assignedTechnician?.name}
                      </Text>
                    </td>
                    <td
                      style={{ wordBreak: "break-word", whiteSpace: "normal" }}
                    >
                      <Text
                        fz="xl"
                        p="sm"
                        ta="center"
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {item.parkingLocation ?? "-"}
                      </Text>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </Flex>
      <Flex justify="center">
        <Pagination
          onChange={setPage}
          value={page}
          total={ticketData?.data?.totalPages ?? 0}
          size="sm"
          radius="sm"
        />
      </Flex>
    </Flex>
  );
};

const QRReaderBox = styled.div`
  width: 70%;
  height: 70%;
  overflow: hidden;
  & video {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 640px) {
    width: 100%;
    height: 100%;

    #qr-shaded-region {
      border-width: 36px 40px !important;
    }
  }
`;

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

export default QR;
