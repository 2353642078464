import {
  createMutationKeys,
  createQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  MasterApiUsersUserIdGetRequest,
  MasterApiUsersUserIdPutRequest,
  UserApiAuthLoginPostRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { AxiosRequestConfig } from "axios";
import BaseInstance, { MasterInstance, UserInstance } from "instance/axios";

export const users = createQueryKeys("users", {
  all: null,
  detail: (params: MasterApiUsersUserIdGetRequest) => {
    return {
      queryKey: [params.userId, params],
      queryFn: () => MasterInstance.usersUserIdGet(params),
    };
  },
  who: (params) => {
    const token = localStorage.getItem("authToken");
    return {
      queryKey: [params],
      queryFn: () =>
        BaseInstance.whoamiGet({
          headers: { Authorization: `Bearer ${token}` },
        }).then((res) => res.data),
    };
  },
});

export const mutateUser = createMutationKeys("users", {
  login: (
    params: UserApiAuthLoginPostRequest,
    options?: AxiosRequestConfig
  ) => ({
    mutationKey: [params.authLoginPostRequest, options],
    mutationFn: () => UserInstance.authLoginPost(params, options),
  }),
  update: (params: MasterApiUsersUserIdPutRequest) => ({
    mutationKey: [params.userId, params.authSignupPostRequest],
    mutationFn: () => MasterInstance.usersUserIdPut(params),
  }),
});
