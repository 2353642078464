export const searchHeader = [
  { value: "", label: "전체" },
  { value: "assignedAdviserName", label: "담당 어드바이저" },
  // { value: "customerName", label: "고객명" },
  { value: "plateCode", label: "차량번호" },
  // { value: "carModel", label: "차량모델" },
  { value: "assignedTechnicianName", label: "담당 테크니션" },
  { value: "parkingLocation", label: "주차위치" },
];

export const allSearchFields = searchHeader
  .filter((type) => type.value)
  .map((type) => type.value);
