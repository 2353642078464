export const StepStatus = {
  RECEIPTED: "접수완료",
  ACCEPT_PENDING: "점검지연",
  TEMPORARY_RECEIPTED: "접수대기",
  WAITING: "점검시작",
  TEMPORARY_STAGE_IN_PROGRESS: "임시 정비진행",
  PENDING: "점검지연",
  STAGE_IN_PROGRESS: "정비진행",
  IN_PROGRESS_PENDING: "정비지연",
  STAGE_WAITING: "정비단계 대기",
  WAITING_PENDING: "정비단계 시작 전 지연",
  PROCESSING: "상세정비",
  PROCESS_PENDING: "상세정비 지연",
  DONE: "정비완료",
  DONE_CONFIRMED: "정비완료 확인",
  DELIVERY_PENDING: "출고지연",
  CLOSED: "정비마감",
  DELIVERED: "출고완료",
};

export const StepStatusData = {
  ACCEPT_PENDING: "ACCEPT_PENDING",
  WAITING: "WAITING",
  TEMPORARY_STAGE_IN_PROGRESS: "TEMPORARY_STAGE_IN_PROGRESS",
  PENDING: "PENDING",
  STAGE_IN_PROGRESS: "STAGE_IN_PROGRESS",
  IN_PROGRESS_PENDING: "IN_PROGRESS_PENDING",
  STAGE_WAITING: "STAGE_WAITING",
  WAITING_PENDING: "WAITING_PENDING",
  PROCESSING: "PROCESSING",
  PROCESS_PENDING: "PROCESS_PENDING",
  DONE: "DONE",
};

// export type StepPendingStatus = {
//     "WAITING": "PENDING",
//     "STAGE_IN_PROGRESS": "IN_PROGRESS_PENDING",
//     "STAGE_WAITING": "WAITING_PENDING",
//     "PROCESSING": "PROCESS_PENDING"
// }

// StepStatusDataPending 정의
export const StepStatusDataPending = {
  RECEIPTED: "ACCEPT_PENDING",
  WAITING: "ACCEPT_PENDING", // 정비 대기 상태일 때는 정비전 지연으로 이동 가능
  TEMPORARY_STAGE_IN_PROGRESS: "PENDING",
  STAGE_IN_PROGRESS: "PENDING",
  STAGE_WAITING: "PENDING",
  PROCESSING: "PENDING",
  DONE_CONFIRMED: "DELIVERY_PENDING",
} as const;

export type StepStatusDataPendingKeys = keyof typeof StepStatusDataPending;

export const InProgressStatus = [
  "STAGE_IN_PROGRESS", // "정비단계 진행중"
  "IN_PROGRESS_PENDING", // "정비단계 진행중 지연중"
  "STAGE_WAITING", // "정비단계 대기중"
  "WAITING_PENDING", // "정비단계 시작 전 지연중"
  "PROCESSING", // "상세 정비중"
  "PROCESS_PENDING", // "상세 정비 지연중"
  "DONE", // "정비 완료"
];
