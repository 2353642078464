import { authEnum } from "config/auth";
import { useNavigate } from "react-router-dom";

export function useLogout() {
  const navigate = useNavigate();

  return () => {
    localStorage?.removeItem(authEnum.authToken);
    localStorage?.removeItem("siteId");

    navigate("/", { replace: true });
  };
}
