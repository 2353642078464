import styled from "@emotion/styled";
import { Flex } from "@mantine/core";
import Header from "components/Layout/Header";
import ProtectedRoute from "components/Router/ProtectedRoute";
import Auth from "pages/auth";
import QR from "pages/qr";
import Repair from "pages/repair";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useCheckAuth } from "utils/checkAuth";

function Index() {
  useLocation();
  const auth = useCheckAuth();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath="/qr">
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qr"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/">
              <QR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/repair/:ticketId"
          element={
            <ProtectedRoute redirectCondition={!auth} redirectPath="/qr">
              <Repair />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

const Layout = () => {
  return (
    <Flex w="100%" h="100%" justify="center">
      <Main>
        <Header />
        <Outlet />
      </Main>
    </Flex>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 20rem; // 320px;
  max-width: 40rem; // 640px;
  padding: 2rem 0.5rem;
  gap: 2rem;
`;

export default Index;
