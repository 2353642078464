import { ActionIcon, Flex } from "@mantine/core";
import { IconLogout, IconUser } from "@tabler/icons-react";
import { useModal } from "components/Modal/ModalStackManager";
import EditForm from "components/UserEditForm";
import { useLogout } from "hook/useLogout";
import { ReactComponent as BenzIcon } from "assets/benz_logo.svg";
import { useNavigate } from "react-router-dom";
import { useCheckAuth } from "utils/checkAuth";

const Header = () => {
  const isLogin = useCheckAuth();
  const navigate = useNavigate();
  const logout = useLogout();
  const { openModal } = useModal();

  return (
    <Flex
      w="100%"
      wrap="wrap"
      align="center"
      justify={isLogin ? "space-between" : "flex-start"}
      h="7.5rem"
      pr="md"
      bg="#1a1c1e"
      style={{
        borderRadius: "7.5rem",
      }}
    >
      <ActionIcon
        variant="transparent"
        w="8rem"
        onClick={() => {
          navigate("/qr");
        }}
      >
        <BenzIcon alt="로고" />
      </ActionIcon>
      {isLogin && (
        <Flex justify="flex-end" gap="lg">
          <ActionIcon
            variant="transparent"
            onClick={() => {
              openModal(<EditForm />, null, "사용자 정보 수정", true);
            }}
          >
            <IconUser />
          </ActionIcon>
          <ActionIcon variant="transparent" onClick={logout}>
            <IconLogout />
          </ActionIcon>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
