import {
  Configuration,
  DefaultApi,
  MasterApi,
  TicketApi,
  TicketUserViewApi,
  UserApi,
} from "@sizlcorp/mbk-api-document/dist/models";
import axios from "axios";

// 1.	✅ axiosInstance에서 Authorization 헤더를 자동으로 관리
// 	  API 인스턴스를 생성할 때 Configuration의 apiKey를 따로 지정하지 않고, axiosInstance가 항상 최신 토큰을 사용하도록 함.
// 2.	✅ 토큰 갱신 로직 추가
//  	401 응답 발생 시 refreshToken을 사용하여 새 accessToken을 가져오고, 기존 요청을 재시도하는 방식으로 변경.
// 3.	✅ API 인스턴스 생성 코드 단순화
//  	createApiInstance() 유틸 함수를 만들어, API 인스턴스를 보다 간결하고 유지보수하기 쉽게 변경.
export const BASE_URL =
  process.env.REACT_APP_BASE_URL ?? "https://mbk-production.sizl.co.kr/api";

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60 * 60 * 24 * 7,
});

// ✅ 요청 인터셉터: 항상 최신 토큰 반영
axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("authToken");
  if (accessToken && config.headers) {
    config.headers.set("Authorization", `Bearer ${accessToken}`);
  }
  return config;
});

// ✅ 응답 인터셉터: 401 발생 시 토큰 갱신 후 재시도
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) return Promise.reject(error);

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) {
          return Promise.reject(error);
        }

        // ✅ 토큰 갱신 API 호출
        const { data } = await axios.post(`${BASE_URL}/auth/refresh`, {
          refreshToken,
        });

        // ✅ 새로운 accessToken 저장
        localStorage.setItem("authToken", data.accessToken);

        // ✅ 기존 요청 재시도
        originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("토큰 갱신 실패:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// ✅ API 인스턴스 생성 함수
const createApiInstance = <T>(
  ApiClass: new (config: Configuration, basePath: string, axios: any) => T
) => {
  return new ApiClass(new Configuration(), BASE_URL, axiosInstance);
};

export const BaseInstance = createApiInstance(DefaultApi);
export const UserInstance = createApiInstance(UserApi);
export const TicketInstance = createApiInstance(TicketApi);
export const MasterInstance = createApiInstance(MasterApi);
export const TicketUserViewInstance = createApiInstance(TicketUserViewApi);

export default BaseInstance;
