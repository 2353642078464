import { MantineProvider } from "@mantine/core";
import ModalStackManager from "components/Modal/ModalStackManager";

import ReactDOM from "react-dom/client";
import GlobalStyle from "style/Global";
import App from "./App";
import { Suspense } from "react";
import { Loading } from "components/Loading";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true, // 모든 쿼리에 대해 suspense 활성화
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <GlobalStyle />
      <Suspense fallback={<Loading />}>
        <ModalStackManager>
          <App />
        </ModalStackManager>
      </Suspense>
    </MantineProvider>
  </QueryClientProvider>
);
