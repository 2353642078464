import {
  MasterApiPendingReasonsFindPostRequest,
  MasterApiProcessesFindPostRequest,
  TicketApiTicketsTicketIdParkingLocationHistoryGetRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { MasterInstance, TicketInstance } from "instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";

export const masters = createQueryKeys("masters", {
  all: null,
  getPendingReason: (params: MasterApiPendingReasonsFindPostRequest) => {
    return {
      queryKey: [JSON.stringify(params)],
      queryFn: () => MasterInstance.pendingReasonsFindPost(params),
    };
  },
  getProcess: (params: MasterApiProcessesFindPostRequest) => {
    return {
      queryKey: [JSON.stringify(params)],
      queryFn: () => MasterInstance.processesFindPost(params),
    };
  },
  getParkingHistory: (
    params: TicketApiTicketsTicketIdParkingLocationHistoryGetRequest
  ) => {
    return {
      queryKey: [JSON.stringify(params)],
      queryFn: () =>
        TicketInstance.ticketsTicketIdParkingLocationHistoryGet(params),
    };
  },
});
