import { Button, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthLoginPostRequest,
  UserApiAuthLoginPostRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateUser } from "api/useUserQuery";
import { AxiosRequestConfig } from "axios";
import { authEnum } from "config/auth";
import { useNavigate } from "react-router-dom";
import decodeToken from "utils/decodeToken";

const Auth = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const form = useForm<AuthLoginPostRequest>({
    initialValues: {
      identifier: "",
      password: "",
    },
  });

  const { mutate: loginMutate } = useMutation(
    ({
      params,
      options,
    }: {
      params: UserApiAuthLoginPostRequest;
      options?: AxiosRequestConfig;
    }) => {
      return mutateUser
        .login(params, options)
        .mutationFn(params as UserApiAuthLoginPostRequest | any);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  const onSubmit = () => {
    try {
      loginMutate(
        {
          params: {
            authLoginPostRequest: form.values,
          },
          options: {
            headers: {
              Authorization: undefined,
            },
          },
        },
        {
          onSuccess: (response) => {
            const token = response.data?.token;
            localStorage.setItem(authEnum.authToken, token);
            const userInfo = decodeToken(token);
            localStorage.setItem("siteId", userInfo.user.siteId);
            navigate("/qr");
          },
          onError: (error: any) => {
            alert(error?.response?.data?.message);
          },
        }
      );
    } catch (e: any) {
      alert(e?.response?.data?.message);
      console.error(e);
    }
  };

  return (
    <Flex
      w="100%"
      direction="column"
      p="3rem"
      gap="3rem"
      bg="black"
      style={{ borderRadius: "4rem" }}
    >
      <Flex w="100%" justify="center" direction="column">
        <Text fz="2rem" color="white">
          아이디
        </Text>
        <TextInput
          w="100%"
          size="xl"
          type="text"
          autoFocus
          {...form.getInputProps("identifier")}
        />
      </Flex>
      <Flex w="100%" justify="center" direction="column">
        <Text fz="2rem" color="white">
          패스워드
        </Text>
        <TextInput
          w="100%"
          size="xl"
          type="password"
          {...form.getInputProps("password")}
        />
      </Flex>
      <Button onClick={onSubmit} size="xl" w="100%" color="indigo">
        로그인
      </Button>
    </Flex>
  );
};

export default Auth;
