import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { searchHeader } from "constants/searchHeader";
import { handleKeyDown } from "utils/event";

interface SearchBoxProps {
  search: string;
  searchFields: string[];
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSearchFields: React.Dispatch<React.SetStateAction<string[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const SearchBox = ({
  searchFields,
  setSearch,
  setSearchFields,
  setPage,
}: SearchBoxProps) => {
  const form = useForm({
    initialValues: {
      search: "",
      searchFields: "",
    },
  });

  const handleSearch = () => {
    setSearch(form.values.search);
    setSearchFields(
      form.values.searchFields && form.values.searchFields !== ""
        ? [form.values.searchFields]
        : searchFields
    );
    setPage(1);
  };

  return (
    <Flex w="100%" direction="column" gap="md" justify="center" align="center">
      <Select
        w="100%"
        data={searchHeader}
        placeholder="통합 검색"
        {...form.getInputProps("searchFields")}
      />
      <TextInput
        placeholder="검색어를 입력하세요"
        {...form.getInputProps("search")}
        w="100%"
        onKeyDown={(e) => handleKeyDown(e, handleSearch)}
      />
      <Button w="100%" onClick={handleSearch}>
        검색
      </Button>
    </Flex>
  );
};

export default SearchBox;
