import {
  TicketApiTicketsTicketIdGetRequest,
  TicketUserViewApiTicketUserViewGetRequest,
} from "@sizlcorp/mbk-api-document/dist/models";
import { TicketInstance, TicketUserViewInstance } from "instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { AxiosRequestConfig } from "axios";

export const tickets = createQueryKeys("tickets", {
  all: null,
  getListView: (params: TicketUserViewApiTicketUserViewGetRequest) => {
    return {
      queryKey: [JSON.stringify(params)],
      queryFn: () => TicketUserViewInstance.ticketUserViewGet(params),
    };
  },
  getView: (
    params: TicketApiTicketsTicketIdGetRequest,
    options: AxiosRequestConfig
  ) => {
    return {
      queryKey: [JSON.stringify(params), JSON.stringify(options)],
      queryFn: () => TicketInstance.ticketsTicketIdGet(params, options),
    };
  },
});
